import React, { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"

import LoginLayout from "../components/login-layout"
import PageLoader from "../components/page-loader"
import { isBrowser } from "../utils/auth"

const LogoutPage = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    if (isBrowser) {
      localStorage.clear()
    }

    logout()
  }, [])

  return (
    <LoginLayout>
      <PageLoader />
    </LoginLayout>
  )
}

export default LogoutPage
